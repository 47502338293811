// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculator-debt-js": () => import("./../../../src/pages/calculator/debt.js" /* webpackChunkName: "component---src-pages-calculator-debt-js" */),
  "component---src-pages-counter-net-worth-js": () => import("./../../../src/pages/counter/net-worth.js" /* webpackChunkName: "component---src-pages-counter-net-worth-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */)
}

